





















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import CommonSafe from "../components/common-safe.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    CommonSafe,
  },
})
export default class Name extends Vue {}
