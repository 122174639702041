const validatePhoneNumber = (phoneNumber: number | string) => {
    if (!phoneNumber) {
        return {
            valide: false,
            message: "手机号不能为空"
        };
    }
    const rex = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
    const result = rex.test(phoneNumber.toString());
    return {
        valide: true,
        message: ""
    };
};

export { validatePhoneNumber };
