/**
 * @description 倒计时
 * @param vCom Vue组件
 * @param time 需要倒计时的时间
 * @param timeName localstorage中存的变量名
 */
const countDownTime = (vCom: any, time: number, timeName: string) => {
    const interval = setInterval(() => {
        if (time > 0) {
            time--;
            vCom.$set(vCom, timeName, time);
            vCom.$forceUpdate();
            localStorage.setItem(timeName, time.toString());
        } else {
            clearInterval(interval);
        }
    }, 1000);

};

export { countDownTime };
